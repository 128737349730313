import * as actions from '../action';
const initialState = []
function areasAtuacaoReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_AREASATUACAO:
            return action.dados
        default:
            return state
    }
}
export default areasAtuacaoReducer