import * as actions from '../action';
const initialState = []
function especialidadesReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_ESPECIALIDADES:
            return action.dados
        default:
            return state
    }
}
export default especialidadesReducer