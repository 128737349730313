import * as actions from '../action';
const initialState = []

function conveniosReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_CONVENIOS:
            return action.dados
        default:
            return state
    }
}
export default conveniosReducer