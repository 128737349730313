import React, { useLayoutEffect, useState, useEffect, useCallback } from "react";
import * as qs from 'query-string';
import {
  Button,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip
} from "reactstrap";
import { useSelector, useDispatch } from 'react-redux'
import ShowMoreText from 'react-show-more-text';
// core components
import SearchPageHeader from "components/Headers/SearchPageHeader";
import DarkFooter from "components/Footers/DarkFooter.js";
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/core";
import * as searchAction from '../../store/action/search.actions'
import * as disponibilidadeAction from '../../store/action/disponibilidade.actions'
import Disponibilidade from './index-sections/Disponibilidade'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import ModalSearch from '../../components/Elements/ModalSearch'
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function SearchDoutorPage(props) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 10,
    }),
  }
  let history = useHistory();
  const dispatch = useDispatch()
  const search = useSelector(store => store.search)
  const [nPages, setNPages] = useState([])
  const [width, setWidth] = useState(window.innerWidth);

  const especialidadesStore = useSelector(store => store.especialidades)
  const areaatuacaoStore = useSelector(store => store.areasatuacao)
  const typesDoutorStore = useSelector(store => store.typesDoutor)
  const conveniosStore = useSelector(store => store.convenios)

  //const [especialidades, setEspecialidades] = useState([])
  //const [areasatuacao, setAreasatuacao] = useState([])

  const [typesValue, setTypesValue] = useState([])
  const [conveniosRel, setConveniosRel] = useState([{ label: "Todos", value: "Todos" }])

  const [conveniosValue, setConveniosValue] = useState([])
  const [especialidadesValue, setEspecialidadesValue] = useState([])
  const [areaAtuacaoValue, setAreaAtuacaoValue] = useState(null)

  const [nome, setNome] = useState("")
  const [valor, setValor] = useState(0)

  const [modalOpen, setModalOpen] = useState(false)
  const [pageSelect, setPageSelect] = useState(1)

  const sendSearch = async (pageOk) => {

    let url = "?"

    if (typesValue && typesValue.length > 0) {
      url = url + "idstype=["
      for (let index = 0; index < typesValue.length; index++) {
        if (index > 0) url += ","
        url = url + typesValue[index].value;
      }
      url = url + "]"
    }
    if (especialidadesValue && especialidadesValue.length > 0) {
      url = url + "&idsespecialidade=["
      for (let index = 0; index < especialidadesValue.length; index++) {
        if (index > 0) url += ","
        url = url + especialidadesValue[index].value;
      }
      url = url + "]"
    }
    if (areaAtuacaoValue && areaAtuacaoValue.length > 0) {
      url = url + "&idsareasatuacao=["
      for (let index = 0; index < areaAtuacaoValue.length; index++) {
        if (index > 0) url += ","
        url = url + areaAtuacaoValue[index].value;
      }
      url = url + "]"
    }

    if (valor && valor > 0) {
      url = url + "&valormaximo="
      url = url + valor;
    }

    if (nome) {
      url = url + "&nomecontem="
      url = url + nome;
    }
    if (pageOk) {
      url = url + "&page=" + pageOk
      setPageSelect(pageOk)
    }
    if (conveniosValue && conveniosValue.length > 0) {
      url = url + "&idsconvenios=["
      for (let index = 0; index < conveniosValue.length; index++) {
        if (index > 0) url += ","
        url = url + conveniosValue[index].value;
      }
      url = url + "]"
    }

    history.push(`/search${url}`);
    searchMedicos(`${url}`)
  }


  const searchMedicos = useCallback((url) => {
    console.log("buscando médicos...")
    const parsed = qs.parse(url);

    let param = {}
    let page = parsed.page

    if (page)
      param.page = page

    if (parsed.idsconvenios) {
      param = Object.assign(param, { idsconvenios: [parsed.idsconvenios] })
    }

    let idstype = []
    if (parsed.idstype) {
      idstype = JSON.parse(parsed.idstype)
      if (typesDoutorStore && typesDoutorStore.length > 0) {
        setTypesValue([])
        for (const iterator of idstype) {
          let typeUrl = typesDoutorStore.find(item => item.idtype === iterator)
          setTypesValue(prevState => [
            ...prevState, {
              label: typeUrl.descricao,
              value: typeUrl.idtype
            }
          ])
        }
      }
      param = Object.assign(param, { idstype })
    }


    let idsconvenios = []
    if (parsed.idsconvenios) {
      idsconvenios = JSON.parse(parsed.idsconvenios)
      if (conveniosStore && conveniosStore.length > 0) {
        setConveniosValue([])
        for (const iterator of idsconvenios) {
          let typeUrl = conveniosStore.find(item => item.idconvenio === iterator)
          setConveniosValue(prevState => [
            ...prevState, {
              label: typeUrl.nome,
              value: typeUrl.idconvenio
            }
          ])
        }
      }
      param = Object.assign(param, { idsconvenios })
    }

    let idsespecialidade = []
    if (parsed.idsespecialidade) {
      idsespecialidade = JSON.parse(parsed.idsespecialidade)
      if (especialidadesStore && especialidadesStore.length > 0) {
        setEspecialidadesValue([])
        for (const iterator of idsespecialidade) {
          let typeUrl = especialidadesStore.find(item => item.idespecialidade === iterator)
          setEspecialidadesValue(prevState => [
            ...prevState, {
              label: typeUrl.descricao,
              value: typeUrl.idespecialidade
            }
          ])
        }
      }
      param = Object.assign(param, { idsespecialidade })
    }

    let idsareasatuacao = []
    if (parsed.idsareasatuacao) {
      idsareasatuacao = JSON.parse(parsed.idsareasatuacao)
      if (areaatuacaoStore && areaatuacaoStore.length > 0) {
        setAreaAtuacaoValue([])
        for (const iterator of idsareasatuacao) {
          let typeUrl = areaatuacaoStore.find(item => item.idareaatuacao === iterator)
          setAreaAtuacaoValue(prevState => [
            ...prevState, {
              label: typeUrl.descricao,
              value: typeUrl.idareaatuacao
            }
          ])
        }
      }
      param = Object.assign(param, { idsareasatuacao })
    }

    if (parsed.valormaximo && parsed.valormaximo > 0)
      param = Object.assign(param, { valormaximo: parsed.valormaximo })
    if (parsed.nomecontem && parsed.nomecontem !== "")
      param = Object.assign(param, { nomecontem: parsed.nomecontem })
    setDisponibilidades([])
    dispatch(searchAction.getMedicos(param))

  }, [areaatuacaoStore, dispatch, especialidadesStore, typesDoutorStore,conveniosStore])


  useLayoutEffect(() => {

    if (conveniosStore && conveniosStore.length > 0) {
      let dados = conveniosStore.map((item, key) => {
        return {
          label: item.nome,
          value: item.idconvenio
        }
      })
      Promise.all(dados).then(res => setConveniosRel(res))
    }

    if (typesDoutorStore && typesDoutorStore.length > 0) {
      let dados = typesDoutorStore.map((item, key) => {
        return {
          label: item.descricao,
          value: item.idtype
        }
      })
      Promise.all(dados).then(res => setTypes(res))
    }


    /*
    if (especialidadesStore && especialidadesStore.length > 0) {
      let dados = especialidadesStore.map((item, key) => {
        return {
          label: item.descricao,
          value: item.idespecialidade
        }
      })
      Promise.all(dados).then(res => setEspecialidades(res))
    }


    if (areaatuacaoStore && areaatuacaoStore.length > 0) {
      let dados = areaatuacaoStore.map((item, key) => {
        return {
          label: item.descricao,
          value: item.idareaatuacao
        }
      })
      Promise.all(dados).then(res => setAreasatuacao(res))
    }
    */
    searchMedicos(props.location.search)


  }, [dispatch, typesDoutorStore, conveniosStore, especialidadesStore, areaatuacaoStore, searchMedicos, props.location.search])


  const [types, setTypes] = useState([])
  const [disponibilidades, setDisponibilidades] = useState([])

  const getDisponibilidades = async (chaveMedico, idtipoatendimento) => {
    let disponibilidade = await disponibilidadeAction.getDisponibilidade(chaveMedico, idtipoatendimento)
    let objeto = {
      chaveMedico,
      disponibilidade,
      idtipoatendimento
    }
    setDisponibilidades(oldArray => [...oldArray, objeto]);
  }

  function refreshPage() {
    //window.location.reload(false);
    searchMedicos(props.location.search)
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    if (search.listaMedicos && search.nMedicos > 0) {
      let totalPaginas = parseInt(search.nMedicos / 10)
      let totalPaginasResto = search.nMedicos % 10
      if (totalPaginas < 1)
        totalPaginas = 1
      else
        totalPaginas = totalPaginasResto > 0 ? totalPaginas + 1 : totalPaginas



      const nPagesElements = []
      for (let index = 0; index < totalPaginas; index++) {
        nPagesElements.push(index + 1)
      }
      setNPages(nPagesElements)

      for (const item of search.listaMedicos) {
        getDisponibilidades(item.chave, item.idtipoatendimento)
      }
    }
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [search]);

  const toggle = (recebe) => {
    let chave = collapse.key === recebe ? -1 : recebe
    setCollapse({
      key: chave
    })

  };
  const [collapse, setCollapse] = useState({
    key: -1
  });

  return (
    <>
      <div className="wrapper">
        <ModalSearch
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          mensagem={{
            titulo: "Filtro de Busca de Médicos"
          }}
          nome={nome}
          setNome={setNome}
          valor={valor}
          setValor={setValor}
          sendSearch={sendSearch}
        />
        <SearchPageHeader />
        <div style={{ padding: 10, marginRight: 15 }}>
          <Row style={{ justifyContent: "center" }}>
            <Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Select
                noOptionsMessage={() => "Não foi Encontrado"}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'gray',
                  },
                })}
                placeholder="O que você procura?"

                isMulti
                name="colors"
                options={types}
                value={typesValue}
                onChange={(e) => setTypesValue(e)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Col>
            <Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Select
                noOptionsMessage={() => "Não foi Encontrado"}
                isClearable
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'gray',
                  },
                })}
                placeholder="Convênios"
                name="colors"
                isMulti
                options={conveniosRel}
                value={conveniosValue}
                onChange={(e) => setConveniosValue(e)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Col>
            {
              /*
<Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Select
                noOptionsMessage={() => "Não foi Encontrado"}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'gray',
                  },
                })}
                placeholder="Especialidades"
                isMulti
                name="colors"
                options={especialidades}
                value={especialidadesValue}
                onChange={(e) => setEspecialidadesValue(e)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Col>
            <Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Select
                noOptionsMessage={() => "Não foi Encontrado"}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'gray',
                  },
                })}
                placeholder="Areas de Atuação"
                isMulti
                name="colors"
                options={areasatuacao}
                value={areaAtuacaoValue}
                onChange={(e) => setAreaAtuacaoValue(e)}
                className="basic-multi-select"
                classNamePrefix="select"
              />

            </Col>
              */
            }


            <Col sm="1" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Button
                onClick={() => setModalOpen(true)}
                style={{ margin: 0, width: "100%" }} id="buttonFilter">
                {width < 575 ? " + Filtros " : ""}
                <i className="now-ui-icons design_bullet-list-67"></i>
              </Button>
              <UncontrolledTooltip target="#buttonFilter">
                + Fitros
              </UncontrolledTooltip>

            </Col>
            <Col sm="1" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Button onClick={() => sendSearch(1)} style={{ margin: 0, width: "100%" }} id="buttonSearch">
                {width < 575 ? " Pesquisar " : ""}
                <i className="now-ui-icons ui-1_zoom-bold"></i>
              </Button>
              <UncontrolledTooltip target="#buttonSearch">
                Pesquisar
              </UncontrolledTooltip>

            </Col>
          </Row>
        </div>
        <div className="section section-contact-us">
          <Container>
            <h2 className="title">Encontre sua consulta aqui!</h2>
            {search.listaMedicos && search.listaMedicos.map((item, key) =>
              <Row key={key} style={{ padding: 10 }}>
                <Col sm="6" xs="12">
                  <div >
                    <Row>
                      <Col xs="3">
                        <div className="hero-images-container">
                          <img
                            alt="..."
                            className="rounded"
                            src={item.midiaPerfil ? item.midiaPerfil : require("../../assets/img/default-avatar.png")}
                          ></img>
                        </div>
                      </Col>
                      <Col xs="9">
                        <Row>
                          <h4><b>{item.nome}</b></h4>
                        </Row>
                        <Row>
                          <span style={{ fontSize: "0.8142em", marginBottom: 10 }}>
                            {item.descricao} {item.documentoproficional} {item.especialidades ? "- " + item.especialidades : ''} {item.areasatuacao ? "(" + item.areasatuacao + ")" : ''}
                          </span>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <ShowMoreText
                          lines={item.minibio && item.minibio.length > 10 ? 2 : -1}
                          more='(Mais)'
                          less='(Menos)'
                          anchorClass=''
                          expanded={false}
                          keepNewLines={true}
                          width={0}
                        >
                          {item.minibio ? item.minibio : ''}


                        </ShowMoreText>
                      </div>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <b>Tempo Médio de Consulta:</b> {item.tempo} minutos.
                      </Col>
                      <Col xs="12">
                        <b>Valor R$ </b>{String(item.valor.toFixed(2)).replace(".", ",")}
                      </Col>
                    </Row>


                  </div>
                </Col>
                <Col sm="6" xs="12">
                  <div style={collapse.key === key ? null : { height: "220px", overflow: "hidden" }}>
                    <div
                      style={{ fontSize: "0.7142em", marginBottom: 10 }} className="text-center">
                      <BeatLoader
                        css={override}
                        sizeUnit={"px"}
                        size={20}
                        color={'#00c3a5'}
                        loading={disponibilidades.find(disponibilidade => disponibilidade.chaveMedico === item.chave && disponibilidade.idtipoatendimento === item.idtipoatendimento) ? false : true}
                      />

                      <Disponibilidade
                        chaveMedico={item.chave}
                        refreshPage={refreshPage}
                        idtipoatendimento={item.idtipoatendimento}
                        disponibilidade={
                          disponibilidades.find(disponibilidade => disponibilidade.chaveMedico === item.chave && disponibilidade.idtipoatendimento === item.idtipoatendimento)
                            ? disponibilidades.find(
                              disponibilidade => disponibilidade.chaveMedico === item.chave && disponibilidade.idtipoatendimento === item.idtipoatendimento).disponibilidade
                            : []}
                      />
                    </div>
                  </div>
                  {item.idtipoatendimento === 1 &&
                    <div className="text-center" style={{ marginTop: 3 }}>
                      <hr />
                      <Button className="btn-neutral" onClick={() => toggle(key)}>
                        {collapse.key === key ? "Menos" : "Mais"} horários {collapse.key === key ? <i className="now-ui-icons arrows-1_minimal-up"></i>
                          : <i className="now-ui-icons arrows-1_minimal-down"></i>}


                      </Button>
                    </div>
                  }
                </Col>
              </Row>
            )}
            <Row style={{ justifyContent: "center" }}>
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    aria-label="Previous"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="fa fa-angle-double-left"
                      ></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
                {nPages.map((item, key) =>
                  <PaginationItem key={key}>
                    <PaginationLink
                      style={{
                        backgroundColor: pageSelect === item ? "gray" : null,
                        color: pageSelect === item ? "white" : null,

                      }}
                      onClick={() => sendSearch(item)}
                    >
                      {item}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    aria-label="Next"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="fa fa-angle-double-right"
                      ></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default SearchDoutorPage;
