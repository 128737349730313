import React, { useState, useEffect, useLayoutEffect } from "react";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'react-hook-form'
// core components
import SecaoPageHeader from "components/Headers/SecaoPageHeader";
import DarkFooter from "components/Footers/DarkFooter.js";
import { useDispatch } from 'react-redux'
import InputMask from "react-input-mask";
import * as userAction from '../../store/action/user.actions'
import ModalMessage from "../../components/Elements/ModalMessage"

function SejaParceiro(props) {
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, reset } = useForm()
  const [focus, setFocus] = useState({});
  const [recaptcha, setRecaptcha] = useState(false);
  const [mensagem, setMensagem] = useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tipo, setTipo] = useState("Empresa")

  useLayoutEffect(() => {

  }, [props, dispatch])



  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const onValueChange = (event) => {
    setTipo(event.target.value)
  }

  const onSubmit = data => {
    window.lintrk('track', { conversion_id: 7481153 });
    data.tipo = tipo
    userAction.sendInteresse(data)
      .then(() => {
        setMensagem({
          titulo: 'Contato realizado com sucesso!',
          msg: "Parabéns, seu registro foi efetuado em nosso sistema. Em breve entraremos em contato.",
          success: true
        })
        setModalOpen(true)
        reset()
      })
      .catch((erro) => {
        setMensagem({ titulo: "Verifique", msg: erro, success: false })
        setModalOpen(true)
      })
  }

  return (
    <>
      <ModalMessage
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mensagem={mensagem}

      />
      <div className="wrapper">
        <SecaoPageHeader titulo={"Parceiro Doutor Call"} />
        <div className="section">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ textAlign: "justify", textJustify: "inter-word" }} className="ck-content">
                  <h1 style={{ color: "green" }}><i><strong>Planos de saúde&nbsp;</strong></i></h1>
                  <p>&nbsp;</p><blockquote><p>Dê um plus no seu serviço de plano de saúde e ofereça ao seu cliente um acompanhamento médico individualizado e promova mais saúde!</p></blockquote><p>&nbsp;</p><p>O seu plano pode fazer parte da telemedicina e impactar a vida de diversas pessoas com doenças crônicas que requerem uma supervisão e mais cuidado durante o tratamento.</p><p>&nbsp;</p>

                  <div style={{ backgroundColor: "#f6f6f6", borderRadius: 10, minHeight: 200, textAlign: "center", padding: 25 }}>
                    <h3><strong>Conheça as vantagens em ser um #ParceiroDoutorCall</strong>:</h3>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="3">
                        <div style={{ minHeight: 80 }}>
                          <div style={{ marginTop: 10 }}>Diminuição de custos com consultas e exames recorrentes</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-diminuição-de-custos.png")}
                        />
                      </Col>
                      <Col md="3">
                        <div style={{ minHeight: 80 }}>
                          <div style={{ marginTop: 10 }}>Monitoramento dos seus pacientes</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-monitoramento-dos-pacientes.png")}
                        />

                      </Col>
                      <Col md="4">
                        <div style={{ minHeight: 80 }}>
                          <div style={{ marginTop: 10 }}>Diferencial sob outros planos de saúde por oferecer um acompanhamento médico proporcionando mais carinho e atenção a quem confia em você</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-diferencial.png")}
                        />
                      </Col>
                      <Col md="1"></Col>
                    </Row>

                  </div>


                  <h1 style={{ color: "#7e4c99" }}><i><strong>Empresas&nbsp;</strong></i></h1>

                  <p>&nbsp;</p><p>A empresa é o local que o colaborador mais permanece durante o dia. Por isso, proporcionar mais qualidade de vida, saúde e bem-estar dentro e fora do escritório traz mais benefícios para todos.&nbsp;</p>
                  <p>&nbsp;</p><blockquote><p>Lembre-se: nada substitui o cuidado!</p></blockquote><p>&nbsp;</p><p>Insira a DoutorCall na sua empresa e ofereça um serviço de telemedicina ao seu colaborador!</p>
                  <br />

                  <div style={{ backgroundColor: "#f6f6f6", borderRadius: 10, minHeight: 200, textAlign: "center", padding: 25 }}>
                    <h3><strong>Vantagens de ser um #ParceiroDoutorCall</strong>:</h3>
                    <Row>
                      <Col md="3">
                        <div style={{ minHeight: 40 }}>
                          <div style={{ marginTop: 10 }}>Mais produtividade entre os colaboradores</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-mais-produtividade.png")}
                        />
                      </Col>
                      <Col md="3">
                        <div style={{ minHeight: 40 }}>
                          <div style={{ marginTop: 10 }}>Com um cuidado recorrente, os danos à saúde diminuem</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-cuidado.png")}
                        />

                      </Col>
                      <Col md="3">
                        <div style={{ minHeight: 40 }}>
                          <div style={{ marginTop: 10 }}>Redução de custos à empresa e ao colaborador</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-redução-custos-2.png")}
                        />
                      </Col>
                      <Col md="3">
                        <div style={{ minHeight: 40 }}>
                          <div style={{ marginTop: 10 }}>Mais saúde, disposição e bem-estar sem sair de casa</div>
                        </div>
                        <br />
                        <img
                          alt="..."
                          height="100"
                          src={require("assets/img/icons/Doutor-Call-ícone-mais-saúde.png")}
                        />
                      </Col>
                    </Row>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ backgroundColor: "#844c9c" }}>
          <Row>

            <Col md="3">
            </Col>
            <Col md="6" style={{ padding: 10 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <h4 style={{ color: "white" }}>
                  Para trilhar essa história com a gente, entre em contato!
                </h4>
              </div>

            </Col>
            <Col md="3">
            </Col>
          </Row>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="3">
              </Col>
              <Col md="3">
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <InputGroup
                    className={
                      "input-lg" + (focus.nome ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_single-02"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ backgroundColor: "white" }}
                      name="nome"
                      placeholder={"Nome"}
                      innerRef={register({ required: true })}
                      type="text"
                      onFocus={() => setFocus({ nome: true })}
                      onBlur={() => setFocus({ nome: false })}
                    ></Input>
                  </InputGroup>
                  {errors.nome && <span style={{ color: "white" }}>Este campo é obrigatório.</span>}
                </div>
              </Col>
              <Col md="3">
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <InputGroup
                    className={
                      "input-lg" + (focus.email ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      placeholder="E-mail"
                      style={{ backgroundColor: "white" }}
                      innerRef={register({ required: true })}
                      type="email"
                      onFocus={() => setFocus({ email: true })}
                      onBlur={() => setFocus({ email: false })}
                    ></Input>
                  </InputGroup>
                  {errors.email && <span style={{ color: "white" }}>Este campo é obrigatório.</span>}
                </div>
              </Col>
              <Col md="3">

              </Col>
              <Col md="3">

              </Col>
              <Col md="3">
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <InputGroup
                    className={
                      "input-lg" + (focus.telefone ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-phone"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      //value={documentsData[item.descricao] ? documentsData[item.descricao] : ''}
                      //onChange={(e) => setDocumentsData(Object.assign({}, documentsData, { [item.descricao]: e.target.value }))}
                      name="telefone"
                      inputRef={register({ required: true })}
                      placeholder="Telefone"
                      type="text"
                      mask={"(99) 99999 9999"}
                      style={{ backgroundColor: "white" }}
                      maskChar=" "
                      tag={InputMask}
                      onFocus={() => setFocus({ telefone: true })}
                      onBlur={() => setFocus({ telefone: false })}
                    ></Input>
                  </InputGroup>
                  {errors.telefone && <span style={{ color: "white" }}>Este campo é obrigatório.</span>}
                </div>
              </Col>
              <Col md="3">
                <div style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                  <div style={{ backgroundColor: "white", borderRadius: 40, padding: 5 }}>
                    <FormGroup check className="form-check-radio" style={{ textAlign: "center" }}>
                      <Label check>
                        <Input
                          defaultChecked
                          defaultValue="Empresa"
                          id="exampleRadios1"
                          name="exampleRadios"
                          onChange={onValueChange}
                          type="radio"
                        ></Input>
                        <span className="form-check-sign"></span>
                        Empresa
                      </Label>
                      &nbsp;&nbsp;&nbsp;
                      <Label check>
                        <Input
                          defaultValue="Plano de Saúde"
                          id="exampleRadios1"
                          name="exampleRadios"
                          onChange={onValueChange}
                          type="radio"
                        ></Input>
                        <span className="form-check-sign"></span>
                        Plano de Saúde
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </Col>
              <Col md="3">

              </Col>

              <Col md="3">

              </Col>
              <Col md="3">
                <div style={{ alignItems: "center", textAlign: "center" }}>
                  <ReCAPTCHA
                    style={{ display: 'inline-block' }}
                    size="normal"
                    sitekey="6LfUB8AZAAAAAD0MKjbnWYQYHJEAOAW4d6m4ZWoC"
                    onChange={(e) => {
                      if (e) setRecaptcha(true)
                      else setRecaptcha(false)
                    }}
                  />

                  {errors.check && <span>Este campo é obrigatório.</span>}
                </div>
              </Col>
              <Col md="3">
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <Button
                    block
                    type="submit"
                    className="btn-round"
                    disabled={!recaptcha}
                    color="success"
                    size="lg"
                  >
                    Enviar Contato
                  </Button>
                </div>
              </Col>
              <Col md="3">

              </Col>





            </Row>
          </form>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default SejaParceiro;
