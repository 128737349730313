import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

export const GET_POST = 'GET_POST'


// Action Creators

export function getPost(chave) {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())
        axios.get(`${api}/open/post/${chave}`)
            .then(response => {
                const { data } = response.data
                Promise.all([
                    dispatch({ type: GET_POST, dados: data })
                ]).then(() => dispatch(loadingAction.changeLoading()))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getPostPromise(chave) {
    return new Promise((resolve, reject) => {
        axios.get(`${api}/open/post/${chave}`)
            .then(response => {
                const { data } = response.data
                resolve(data)
            })
            .catch(function (error) {
                reject(error)
            });
    })
}
