//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

export const GET_AREASATUACAO = 'GET_AREASATUACAO'
// Action Creators



export function getAreasAtuacao() {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())

        axios.get(`${api}/open/areasatuacao`)
            .then(response => {
                const { data } = response.data
                Promise.all([
                    dispatch({ type: GET_AREASATUACAO, dados: data })
                ]).then(() => dispatch(loadingAction.changeLoading()))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
