import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "assets/css/ckeditor.css"
import "assets/css/personalize.css"


// pages for this kit
import Index from "views/pages/Index.js";
import NucleoIcons from "views/examples/NucleoIcons.js";
import LoginPage from "views/pages/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
import CreateUserPage from "views/pages/CreateUserPage";
import ValidatePage from "views/pages/ValidatePage";
import EsqueceuPage from "views/pages/EsqueceuPage";
import MailValidatePage from "views/pages/MailValidatePage";

import SecaoPage from "views/pages/SecaoPage";
import PostPage from "views/pages/PostPage";
import PostsPage from "views/pages/PostsPage";

import SearchDoutorPage from "views/pages/SearchDoutorPage";



import ProfilePage from "views/examples/ProfilePage.js";

import { Provider as StoreProvider } from 'react-redux'
import store from './store/reducer'

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SejaParceiro from "views/pages/SejaParceiroPage";


const App = () => {

  
    return (
        <StoreProvider store={store}>
            <BrowserRouter>
                <IndexNavbar />
                <Switch>
                    <Switch>
                        <Route path="/" exact render={(props) => <Index {...props} />} />
                        <Route
                            path="/cadastro"
                            render={(props) => <CreateUserPage {...props} />}
                        />

                        <Route
                            path="/search"
                            render={(props) => <SearchDoutorPage validate={true}  {...props} />}
                        />

                        <Route
                            path="/validate/:chave"
                            render={(props) => <ValidatePage validate={true}  {...props} />}
                        />

                        <Route
                            path="/secao/:chave"
                            render={(props) => <SecaoPage validate={true}  {...props} />}
                        />

                        <Route
                            path="/post/:chave"
                            render={(props) => <PostPage validate={true}  {...props} />}
                        />

                        <Route
                            path="/posts"
                            render={(props) => <PostsPage validate={true}  {...props} />}
                        />

                        <Route
                            path="/parceiro"
                            render={(props) => <SejaParceiro {...props} />}
                        />

                        <Route
                            path="/esqueceu"
                            render={(props) => <EsqueceuPage {...props} />}
                        />

                        <Route
                            path="/mail-validate"
                            render={(props) => <MailValidatePage {...props} />}
                        />

                        <Route
                            path="/unvalidate/:chave"
                            render={(props) => <ValidatePage validate={false} {...props} />}
                        />
                        <Route
                            path="/nucleo-icons"
                            render={(props) => <NucleoIcons {...props} />}
                        />
                        <Route
                            path="/landing-page"
                            render={(props) => <LandingPage {...props} />}
                        />
                        <Route
                            path="/profile-page"
                            render={(props) => <ProfilePage {...props} />}
                        />
                        <Route
                            path="/login"
                            render={(props) => <LoginPage {...props} />}
                        />
                        <Redirect to="/" />
                    </Switch>
                </Switch>
            </BrowserRouter>
        </StoreProvider>
    );
};

export default App;