import * as actions from '../action';
const initialState = {}
function searchReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_MEDICOS:
            return action.dados
        case actions.CLEAR_MEDICOS:
            return initialState
        default:
            return state
    }
}
export default searchReducer