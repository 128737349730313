//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';
import * as postAction from './post.actions';
import * as midiasAction from './midia.actions';
export const GET_POSTS = 'GET_POSTS'

export function getPosts(limit = 10, search = '', midias = false) {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())
        axios.get(`${api}/open/post?limit=${limit}&search=${search}`)
            .then(async (response) => {
                const { data } = response.data
                if (midias) {
                    for (let index = 0; index < data.length; index++) {
                        let post = await postAction.getPostPromise(data[index].chave)
                        if (post.midias) {
                            for (const midia of post.midias) {
                                if (midia.funcao === "Superior") {
                                    let imagem = await midiasAction.getMidia(midia.chave)
                                    data[index].midiaSuperior = imagem
                                    break
                                }
                            }
                        }
                    }
                }
                Promise.all([
                    dispatch({ type: GET_POSTS, dados: data })
                ]).then(() => dispatch(loadingAction.changeLoading()))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

