//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
import * as midiasAction from './midia.actions';
import * as LocalAtendimentoAction from './localatendimento.actions'
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';
export const GET_MEDICOS = 'GET_MEDICOS'
export const CLEAR_MEDICOS = 'CLEAR_MEDICOS'

export function clearMedicos() {
    return (dispatch, getState) => {
        return dispatch({
            type: CLEAR_MEDICOS,
        })
    }
}


export function getMedicos(param) {
    axios.defaults.headers.common['local'] = LocalAtendimentoAction.getToken();

    let apiService = `/open/medico?`
    for (var i in param) {
        if (Array.isArray(param[i]))
            apiService += `${i}=[${param[i]}]&`
        else
            apiService += `${i}=${param[i]}&`
    }

    return (dispatch) => {
        dispatch(loadingAction.changeLoading())
        axios.get(`${api}${apiService}`)
            .then(async (response) => {
                const { data } = response.data
                const { listaMedicos } = data
                if (listaMedicos && listaMedicos.length > 0) {
                    for (let index = 0; index < listaMedicos.length; index++) {
                        if (listaMedicos[index].midias) {
                            let listaMidias = listaMedicos[index].midias.split(",")
                            for (const midia of listaMidias) {
                                let analise = midia.split("=", 2)
                                if (analise[0] === "perfil") {
                                    try {
                                        let imagem = await midiasAction.getMidia(analise[1])
                                        data.listaMedicos[index].midiaPerfil = imagem
                                    } catch (error) {

                                    }
                                    break
                                }
                            }
                        }
                    }
                }
                await dispatch({ type: GET_MEDICOS, dados: data })
                await dispatch(loadingAction.changeLoading())
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
