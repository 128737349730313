import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment'
import * as LocalAtendimentoAction from "../../../store/action/localatendimento.actions"
import { app } from "../../../store/action/settings"

export default function Disponibilidade(props) {



    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const analisaDia = (nDia) => {
        switch (nDia) {
            case 0:
                return "Dom"
            case 1:
                return "Seg"
            case 2:
                return "Ter"
            case 3:
                return "Qua"
            case 4:
                return "Qui"
            case 5:
                return "Sex"
            case 6:
                return "Sab"
            default:
                break;
        }

    }
    return (
        Array.isArray(props.disponibilidade) ?
            <Carousel
                responsive={responsive}
                containerClass="carousel-container"
                centerMode={true}
            >
                {
                    props.disponibilidade.map((item, key) =>
                        <div key={key} style={{ minHeight: 200 }}>
                            {analisaDia(item.diasemana)}
                            <br />
                            {moment(item.data).format("DD/MM")}
                            {item.horarios.map((horario, keyH) =>
                                horario.status > 0 ?
                                    <div
                                        key={keyH}
                                        style={
                                            { backgroundColor: "#ccf3ed", color: "gray", textDecoration: "line-through", padding: 5, margin: 5, textAlign: "center" }
                                        }>
                                        {moment(horario.horainicio, "hh:mm:ss").format("H:mm")}</div>
                                    :
                                    <a href={`${app}/pages/compra/${moment(item.data).format("DD/MM/YYYY")}/${horario.chave}`} style={{ textDecoration: "none" }} key={keyH}>
                                        <div
                                            style={
                                                { backgroundColor: "#00c3a5", color: "white", padding: 5, margin: 5, textAlign: "center" }
                                            }>
                                            {moment(horario.horainicio, "hh:mm:ss").format("H:mm")}</div>
                                    </a>

                            )}
                        </div>

                    )
                }
            </Carousel>
            : <div style={{ justifyContent: "center", alignItems: "center", justifyItems: "center", width: "100%" }}>
                <h1>
                    {
                        props.disponibilidade === 0 ?
                            <>
                                Offline <br />
                                <span style={{ fontSize: 11 }}>Este médico não está disponível no momento.</span>
                            </> :
                            props.disponibilidade === 1 ?
                                <a href={`${app}/pages/compra/${props.chaveMedico}/${props.idtipoatendimento}/${LocalAtendimentoAction.getToken()}`} style={{ textDecoration: "none" }}>
                                    <div
                                        style={
                                            { backgroundColor: "#00c3a5", color: "white", padding: 5, textAlign: "center", borderRadius: 10, width: 200, margin: "0px auto" }
                                        }>
                                        Online
                                    </div>
                                    <span style={{ fontSize: 11 }}>Clique aqui para realizar uma consulta online com este médico.</span>
                                </a>
                                :
                                <>
                                    Em atendimento <br />
                                    <span style={{ fontSize: 11 }}>
                                        Este médico está em atendimento neste momento. <br />
                                        <div style={{ color: "#f96332", cursor: "pointer" }} onClick={props.refreshPage}>Clique  aqui para atualizar o status.</div></span>
                                </>

                    }

                </h1>
            </div>

    )
}
