import React, { useState } from "react";
import { useForm } from 'react-hook-form'
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from 'react-redux'
// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/core";
import * as userAction from '../../store/action/user.actions'
import ModalMessage from "../../components/Elements/ModalMessage"
import ReCAPTCHA from "react-google-recaptcha";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


function MailValidatePage() {
  const [focus, setFocus] = React.useState({});
  const documents = useSelector(store => store.documents)
  const { register, handleSubmit, errors, reset } = useForm()
  const [mensagem, setMensagem] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = data => {
    setLoading(true)
    userAction.envioemailativacao(data.email)
      .then(() => {
        setLoading(false)
        setMensagem({ titulo: "E-mail de Ativação", msg: "E-mail de ativação enviado com sucesso. Verifique seu e-mail!", success: true })
        setModalOpen(true)
        reset()
      })
      .catch((erro) => {
        setMensagem({ titulo: "E-mail de Ativação", msg: erro, success: false })
        setModalOpen(true)
        setLoading(false)
      })
  }

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [documents]);



  return (
    <>
      <ModalMessage
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mensagem={mensagem}
      />
      <div className="wrapper">
        <LandingPageHeader titulo="" />
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Não recebeu o e-mail de ativação?</h2>
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={20}
              color={'#007bff'}
              loading={loading}
            />
            <p className="description">Informe o e-mail de cadastro para receber um novo e-mail de validação de conta.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup
                    className={
                      "input-lg" + (focus.email ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      placeholder="Email..."
                      innerRef={register({ required: true })}
                      type="email"
                      onFocus={() => setFocus({ email: true })}
                      onBlur={() => setFocus({ email: false })}
                    ></Input>
                  </InputGroup>
                  {errors.email && <span>Este campo é obrigatório.</span>}
                  <div className="send-button">
                    <ReCAPTCHA
                      style={{ display: 'inline-block' }}
                      size="normal"
                      sitekey="6LfUB8AZAAAAAD0MKjbnWYQYHJEAOAW4d6m4ZWoC"
                      onChange={(e) => {
                        if (e) setRecaptcha(true)
                        else setRecaptcha(false)
                      }}
                    />
                    <Button
                      block
                      type="submit"
                      className="btn-round"
                      disabled={!recaptcha}
                      color="info"
                      size="lg"
                    >
                      Solicitar novo e-mail de ativação.
                  </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default MailValidatePage;
