import { api } from './settings'
import axios from 'axios';

export const getMidia = (chave) => {
    const apiService = `/open/midia/${chave}`

    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`, { responseType: 'arraybuffer' })
            .then((res) => {
                let image = btoa(
                    new Uint8Array(res.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                resolve(`data:${res.headers['content-type'].toLowerCase()};base64,${image}`)
            })
            .catch((error) => {
                if (error.response) { reject("error.response.data.error") }
                else { reject("Servidor Offline!") }
            })
    });
}