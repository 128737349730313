import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
//Middleware para ações assíncronas
import thunk from 'redux-thunk'

import documents from './documents.reducer';
import loading from './loading.reducer';
import especialidades from './especialidades.reducer';
import secoes from './secoes.reducer';
import secao from './secao.reducer';
import posts from './posts.reducer';
import post from './post.reducer';
import postssearch from './postssearch.reducer';

import typesDoutor from './typesdoutor.reducer'
import typesPaciente from './typespaciente.reducer'
import areasatuacao from './areasatuacao.reducer'
import search from './seach.reducer'
import midiasgeral from './midiasgeral.reducer'
import convenios from './convenios.reducer'







// combineReducers é uma função para unir diversos reducers em um só
const rootReducer = combineReducers({
    secoes,
    loading,
    especialidades,
    documents,
    secao,
    typesDoutor,
    typesPaciente,
    areasatuacao,
    search,
    posts,
    post,
    postssearch,
    midiasgeral,
    convenios
});

//Em Desenvolvimento
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
//Em Produção
//const store = createStore(rootReducer, applyMiddleware(thunk))


export default store


