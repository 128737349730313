import React, { useState, useEffect } from 'react'
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function CarouselFull(props) {

    const [lista, setLista] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [openLightBox, setOpenLightBox] = useState(false);
    const [ordem, setOrdem] = useState(0);

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === lista.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? lista.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    useEffect(() => {
        if (props.lista)
            setLista(props.lista)
    }, [props])
    return (
        <>
            {openLightBox &&
                <Lightbox
                    reactModalStyle={{ zIndex: 1200 }}
                    mainSrc={lista[ordem].src}
                    nextSrc={lista[(ordem + 1) % lista.length].src}
                    prevSrc={lista[(ordem + lista.length - 1) % lista.length].src}
                    onCloseRequest={() => setOpenLightBox(false)}
                    onMovePrevRequest={() =>
                        setOrdem((ordem + lista.length - 1) % lista.length)
                    }
                    onMoveNextRequest={() =>
                        setOrdem((ordem + lista.length + 1) % lista.length)
                    }
                />
            }
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators
                    items={lista}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {lista.map((item, key) => {
                    return (
                        <CarouselItem
                            onExiting={onExiting}
                            onExited={onExited}
                            key={item.src}
                        >
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setOrdem(key)
                                    setOpenLightBox(true)
                                }}>
                                <img src={item.src} alt={item.altText} style={{ width: "100%" }} />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>{item.caption}</h5>
                                </div>
                            </div>
                        </CarouselItem>
                    );
                })}
                <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                        e.preventDefault();
                        previous();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                        e.preventDefault();
                        next();
                    }}
                    role="button"
                >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
            </Carousel>
        </>
    )
}

export default CarouselFull;