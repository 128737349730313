import * as actions from '../action';
const initialState = []
function documentsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_DOCUMENTS:
            return action.dados
        default:
            return state
    }
}
export default documentsReducer