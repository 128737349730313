import React, { useEffect, useState, useLayoutEffect } from "react";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
//import RangeSlider from 'react-bootstrap-range-slider';
import { Link, useHistory } from 'react-router-dom'
// reactstrap components
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import Select from 'react-select'
import { useSelector } from 'react-redux'

function SearchConsulta() {
  let history = useHistory();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 10,
    }),
  }

  const [iconPills] = React.useState("1");
  const especialidadesStore = useSelector(store => store.especialidades)
  const typesDoutorStore = useSelector(store => store.typesDoutor)
  const secoes = useSelector(store => store.secoes)

  const [especialidades, setEspecialidades] = useState([])
  const [types, setTypes] = useState([])
  const [typesValue, setTypesValue] = useState(null)
  //const [especialidadesValue, setEspecialidadesValue] = useState(null)


  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {

    if (typesDoutorStore && typesDoutorStore.length > 0) {
      let dados = typesDoutorStore.map((item, key) => {
        return {
          value: item.idtype,
          label: item.descricao
        }
      })
      Promise.all(dados).then(res => setTypes(res))

    }



    if (especialidadesStore && especialidadesStore.length > 0) {
      let dados = especialidadesStore.map((item, key) => {
        return {
          value: item.idespecialidade,
          label: item.descricao
        }
      })
      Promise.all(dados).then(res => setEspecialidades(res))

    }
  }, [especialidadesStore, typesDoutorStore])

  const sendSearch = () => {
    let url = "?"
    if (typesValue && typesValue.length > 0) {
      url = url + "idstype=["
      for (let index = 0; index < typesValue.length; index++) {
        if (index > 0) url += ","
        url = url + typesValue[index].value;
      }
      url = url + "]"
    }
    /*
    if (especialidadesValue && especialidadesValue.length > 0) {
      url = url + "&idsespecialidade=["
      for (let index = 0; index < especialidadesValue.length; index++) {
        if (index > 0) url += ","
        url = url + especialidadesValue[index].value;
      }
      url = url + "]"
    }
    */
    history.push(`/search${url}`);
  }

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));

  }, [especialidades])
  return (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10" xl="8">
            <Card style={{ background: 'rgba(255,255,255, 0.5)' }}>
              <CardBody>
                <TabContent
                  className="text-center"
                  activeTab={"iconPills" + iconPills}
                >
                  <TabPane tabId="iconPills1">
                    <Row>
                      <Col sm="12" xs="12" style={{ marginTop: 5 }}>
                        <Select
                          noOptionsMessage={() => "Não foi Encontrado"}
                          styles={customStyles}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'gray',
                            },
                          })}
                          placeholder="O que você procura?"

                          isMulti
                          name="colors"
                          options={types}
                          value={typesValue ? typesValue : []}
                          onChange={(e) => setTypesValue(e)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Col>
                      {
                        /*
                      <Col sm="6" xs="12" style={{ marginTop: 5 }}>
                        <Select
                          noOptionsMessage={() => "Não foi Encontrado"}
                          styles={customStyles}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'gray',
                            },
                          })}
                          placeholder="Selecione a especialidade"
                          value={especialidadesValue ? especialidadesValue : []}
                          onChange={(e) => setEspecialidadesValue(e)}
                          isMulti
                          name="colors"
                          options={especialidades}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Col>
                        */
                      }

                      <Col sm="3" xs="12"></Col>
                      {
                        /*
                      <Col sm="6" xs="12">
                        <div style={{ marginTop: 20 }}>
                          <h6>
                            Valor Médio de Consulta menor que
                            </h6>
                        </div>
                        <Row>
                          <Col sm="8" xs="12">
                            <RangeSlider
                              value={value}
                              
                              onChange={e => setValue(e.target.value)}
                              min={1}
                              max={1000}
                            />
                          </Col>
                          <Col sm="4" xs="12">
                            <FormGroup>
                              <Input
                                style={{ backgroundColor: "white", borderRadius: "5px !important"}}
                                value={`R$ ${value}`}
                                placeholder="Valor Máximo"
                                disabled
                                onChange={e => setValue(e.target.value)}
                                type="text"
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      */
                      }
                      <Col sm="6" xs="12">
                        <div style={{ marginTop: 20 }}>
                          <Button
                            className="btn-round mr-1"
                            style={{ backgroundColor: "#844c9c" }}
                            role="button"
                            onClick={sendSearch}
                            size="lg"
                          >Encontrar uma consulta!</Button>
                        </div>
                      </Col>
                      <Col sm="3" xs="12"></Col>

                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Row style={{ width: "100%", justifyContent: "center" }} hidden={width < 991}>
            Não descuide da sua saúde. <br />
            Encontre uma consulta online para começar ou continuar o seu tratamento sem sair de casa.
          </Row>
          <Row style={{ width: "100%", justifyContent: "center", marginTop: 30 }} hidden={width < 580}>
            {secoes.map((item, key) => {
              /*
                0 - Cabeçalho
                1 - Rodapé
                2 - Capa
              */
              if (item.tipo === 2)
                return <Col className="text-center" sm="4" xs="4" key={key}>
                  <Button
                    outline
                    style={{ backgroundColor: 'rgb(132, 76, 156)', border: 0 }}
                    role="button"
                    tag={Link}
                    to={`/secao/${item.chave}`}
                    size="sm"
                  >
                    {item.menu}
                  </Button>
                </Col>
              return null
            })}
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default SearchConsulta;
