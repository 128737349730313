import * as actions from '../action';
const initialState = []

function postsReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_POSTS:
            return action.dados
        default:
            return state
    }
}
export default postsReducer