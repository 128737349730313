import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

// core components
import TransparentFooter from "components/Footers/TransparentFooter.js";
import * as LocalAtendimentoAction from "../../store/action/localatendimento.actions"
import ReCAPTCHA from "react-google-recaptcha";

function LoginPage() {
  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);
  const [usuario, setUsuario] = useState("")
  const [password, setPassword] = useState("")
  const [local, setLocal] = useState({})
  const [msg, setMsg] = useState("")
  const [recaptcha, setRecaptcha] = useState(false);

  const login = () => {

    LocalAtendimentoAction.signin({ usuario, password })
      .then(res => {
        getLocal()
        setMsg("")
      }).catch(erro => {
        setMsg(erro)
      })
  }


  const logoff = () => {
    LocalAtendimentoAction.delToken()
    getLocal()
  }


  const getLocal = () => {
    if (LocalAtendimentoAction.getToken()) {
      LocalAtendimentoAction
        .getLocal()
        .then(res => {
          setLocal(res)
        }).catch(erro => {
          console.log(erro)
        })
    } else {
      setLocal({})
    }
  }

  useEffect(() => {

    getLocal()

    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);



  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain" >
                {!local.descricao ?
                  <Form action="" className="form" method="">
                    <CardHeader className="text-center" style={{ marginTop: 60 }}>
                      <h5>LOCAL DE ATENDIMENTO</h5>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                        style={{ backgroundColor: "rgb(255, 255, 255,0.3)", borderRadius: 22 }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Usuário..."
                          type="text"
                          onChange={(e) => setUsuario(e.target.value)}
                          value={usuario}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                        style={{ backgroundColor: "rgb(255, 255, 255,0.3)", borderRadius: 22, marginBottom: 5 }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons objects_key-25"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Senha..."
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <span style={{ color: "red" }}>{msg}</span>
                      <ReCAPTCHA
                        style={{ display: 'inline-block' }}
                        size="normal"
                        sitekey="6LfUB8AZAAAAAD0MKjbnWYQYHJEAOAW4d6m4ZWoC"
                        onChange={(e) => {
                          if (e) setRecaptcha(true)
                          else setRecaptcha(false)
                        }}
                      />
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={login}
                        size="lg"
                        disabled={!recaptcha}
                      >
                        Identificar-se
                    </Button>
                      <div className="pull-right">
                        <h6>
                          <a
                            className="link"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Precisa de Ajuda?
                        </a>
                        </h6>
                      </div>
                    </CardFooter>
                  </Form>
                  : <div>
                    <CardHeader className="text-center" style={{ marginTop: 60 }}>
                      <h6>LOCAL DE ATENDIMENTO</h6>
                    </CardHeader>
                    <CardBody>
                      <h3>{local.descricao}</h3>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={logoff}
                        size="lg"
                      >
                        Sair
                    </Button>
                      <div className="pull-right">
                        <h6>
                          <a
                            className="link"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Precisa de Ajuda?
                        </a>
                        </h6>
                      </div>
                    </CardFooter>
                  </div>}
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default LoginPage;
