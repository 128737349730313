//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
//import * as loadingAction from './loading.actions';

// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

export function getDisponibilidade(chaveMedico, idtipoatendimento) {

    let apiService = `/open/medico/disponibilidades?chaveMedico=${chaveMedico}&idTipoAtendimento=${idtipoatendimento}`

    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then(async (response) => {
                const { data } = response.data
                resolve(data)
            })
            .catch(function (error) {
                console.log(error);
                reject(error)
            });
    })

}
