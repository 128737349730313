import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from 'lodash'
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PostPageHeader from "components/Headers/PostPageHeader";
import DarkFooter from "components/Footers/DarkFooter.js";
import * as PostAction from "../../store/action/post.actions"
import * as MidiasAction from "../../store/action/midia.actions"
import CarouselFull from 'components/Elements/CarouselFull'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
//https://github.com/jaketrent/react-social-icons


function PostPage(props) {
  const post = useSelector(store => store.post)
  const dispatch = useDispatch()
  const [items, setItems] = useState({})


  useLayoutEffect(() => {
    const { chave } = props.match.params
    dispatch(PostAction.getPost(chave))

  }, [props, dispatch])



  useEffect(() => {
    console.log(post)
    const getMidias = async () => {
      let midiasGet = []
      for (const item of post.midias) {
        const midia = await MidiasAction.getMidia(item.chave)
        midiasGet.push({ src: midia, altText: item.legenda, caption: item.legenda, legenda: item.legenda, chave: item.chave, funcao: item.funcao })
      }
      let grouped = _.groupBy(midiasGet, function (item) {
        return item.funcao;
      });
      setItems(grouped)
    }

    if (post.midias)
      getMidias()


    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [post]);

  return (
    <>
      <div className="wrapper">
        <PostPageHeader />
        <div className="section">
          <Container style={{ padding: 20 }}>
            <Row className="text-center mt-5" style={{ marginBottom: 20 }}>
              <Col className="ml-auto mr-auto" md="8">
                <h2>{post.titulo}</h2>
              </Col>
              <Col className="ml-auto mr-auto" md="10" style={{ marginTop: 10 }}>
                <p className="blockquote blockquote-info">
                  "{post.subtitulo}"
                </p>
                <div style={{ paddingLeft: 10, paddingRight: 10, minWidth: 150, textAlign: "center", justifyContent: "center", height: 20, float: "right", backgroundColor: 'rgb(132, 76, 156)', }}>
                  <span
                    style={{ fontSize: 10, color: "white" }}
                  >
                    {post.categoria} - {moment(post.data, 'YYYY-MM-DDThh:mm:ss a').format("DD/MM/YYYY hh:mm")}
                  </span>
                </div>

              </Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <div style={{ display: "block", width: "50%", float: "right", margin: 20 }}>
                  <CarouselFull
                    lista={post.midias && items.Superior ? items.Superior : []}
                  />
                </div>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }} className="ck-content" dangerouslySetInnerHTML={{ __html: post.texto }}></div>
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <CarouselFull
                  lista={post.midias && items.Galeria ? items.Galeria : []}
                />
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <CarouselFull
                  lista={post.midias && items.Inferior ? items.Inferior : []}
                />
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row style={{ marginLeft: 80, marginRight: 80 }}>
              <p className="blockquote blockquote-info" style={{ width: "100%", minHeight: 10, padding: 5, textAlign: "center" }}>
                <Button
                  className="btn-icon btn-round"
                  style={{ backgroundColor: 'rgb(132, 76, 156)' }}
                  tag={Link}
                  to={`/posts`}
                >
                  <i className="now-ui-icons gestures_tap-01"></i>
                </Button>
                <span style={{ marginLeft: 10, marginTop: 15 }}>
                  <Link to={`/posts`} style={{ color: 'rgb(132, 76, 156)' }}>
                    Visualize nossos conteúdos e fique por dentro da área da saúde!
                                    </Link>
                </span>
              </p>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default PostPage;
