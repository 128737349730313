//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

export const GET_TYPESDOUTOR = 'GET_TYPESDOUTOR'
export const GET_TYPESPACIENTE = 'GET_TYPESPACIENTE'
// Action Creators

export function getTypesDoutor() {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())
        axios.get(`${api}/open/typeuser/?tipo=Doutor`)
            .then(async (response) => {
                const { data } = response.data
                await dispatch({ type: GET_TYPESDOUTOR, dados: data })
                await dispatch(loadingAction.changeLoading())
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getTypesPaciente() {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())

        axios.get(`${api}/open/typeuser/?tipo=Paciente`)
            .then(async (response) => {
                const { data } = response.data
                await dispatch({ type: GET_TYPESPACIENTE, dados: data })
                await dispatch(loadingAction.changeLoading())
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
