/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import SearchConsulta from "views/pages/index-sections/SearchConsulta";


function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  // filter-color="blue"
  return (
    <>
      
      <div className="page-header clear-filter" filter-color="blue" >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/screen-section-header.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <h1 className="title" style={{ marginBottom: 15 }}>Agende sua consulta online</h1>
          <SearchConsulta />
          <h6 className="category category-absolute">
            © 2020 criado por {" "} Doutor Call Telemedicina
          </h6>

        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
