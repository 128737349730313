import React from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
} from "reactstrap";
import './css/ModalMessage.css'
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
function ModalSearch(props) {

    return (

        <Modal isOpen={props.modalOpen} toggle={() => props.setModalOpen(false)}>
            <div className="modal-header justify-content-center">
                <button
                    className="close"
                    type="button"
                    onClick={() => props.setModalOpen(false)}
                >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                </button>
                <h4 className="title title-up">{props.mensagem ? props.mensagem.titulo : ''}</h4>
            </div>
            <ModalBody>
                
                <Row>
                    <Col sm="12" xs="12">
                        <Input
                            style={{ minHeight: "38px", borderRadius: 5, width: "95%" }}
                            name="nome"
                            value={props.nome}
                            onChange={(e) => props.setNome(e.target.value)}
                            placeholder={"Nome do Médico"}
                            type="text"
                        ></Input>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" xs="12">
                        <div style={{ fontSize: "0.9142em", marginTop: 7 }}>
                            Valor Máximo:
                        </div>
                    </Col>
                    <Col sm="8" xs="12">
                        <RangeSlider
                            value={props.valor}
                            onChange={e => props.setValor(e.target.value)}
                            step={1}
                            tooltipLabel={currentValue => `R$ ${currentValue}`}
                            tooltip='on'
                            min={0}
                            max={500}
                        />
                    </Col>
                </Row>

                <p className="justify-content-center">
                    {props.mensagem ? props.mensagem.msg : ''}
                </p>
            </ModalBody>
            <div className="modal-footer">
                <div>

                </div>
                <Button
                    color={'info'}
                    type="button"
                    onClick={() => {
                        props.sendSearch()
                        props.setModalOpen(false)
                    }}
                >
                    Pesquisar
                  </Button>
            </div>
        </Modal>

    );
}

export default ModalSearch;

/*
    <Modal isOpen={props.modalOpen} toggle={() => props.setModalOpen(false)}>
        <div className="modal-header justify-content-center">
            <button
                className="close"
                type="button"
                onClick={() => props.setModalOpen(false)}
            >
                <i className="now-ui-icons ui-1_simple-remove"></i>
            </button>
            <h4 className="title title-up">Modal title</h4>
        </div>
        <ModalBody>
            <p>
                Far far away, behind the word mountains, far from the
                countries Vokalia and Consonantia, there live the blind
                texts. Separated they live in Bookmarksgrove right at the
                coast of the Semantics, a large language ocean. A small
                river named Duden flows by their place and supplies it with
                the necessary regelialia. It is a paradisematic country, in
                which roasted parts of sentences fly into your mouth.
              </p>
        </ModalBody>
        <div className="modal-footer">
            <Button color="default" type="button">
                Nice Button
              </Button>
            <Button
                color="danger"
                type="button"
                onClick={() => props.setModalOpen(false)}
            >
                Close
              </Button>
        </div>
    </Modal>
*/
/*
        <Modal
            modalClassName="modal-mini modal-info"
            toggle={() => props.setModalOpen(false)}
            isOpen={props.modalOpen}
        >
            <div className="modal-header justify-content-center">
                <div className="modal-profile">
                    <i className="now-ui-icons users_circle-08"></i>
                </div>
            </div>
            <ModalBody>
                <p>Always have an access to your profile</p>
            </ModalBody>
            <div className="modal-footer">
                <Button className="btn-neutral" color="link" type="button">
                    Back
                </Button>
                <Button
                    className="btn-neutral"
                    color="link"
                    type="button"
                    onClick={() => props.setModalOpen(false)}
                >
                    Close
                    </Button>
            </div>
        </Modal>
    */