import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from 'lodash'
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SecaoPageHeader from "components/Headers/SecaoPageHeader";
import DarkFooter from "components/Footers/DarkFooter.js";
import * as SecaoAction from "../../store/action/secao.actions"
import * as MidiasAction from "../../store/action/midia.actions"
import CarouselFull from 'components/Elements/CarouselFull'

import { useSelector, useDispatch } from 'react-redux'

function SecaoPage(props) {
  const secao = useSelector(store => store.secao)
  const dispatch = useDispatch()
  const [items, setItems] = useState([])


  useLayoutEffect(() => {
    const { chave } = props.match.params
    dispatch(SecaoAction.getSecao(chave))

  }, [props, dispatch])



  useEffect(() => {
    const getMidias = async () => {
      let midiasGet = []
      for (const item of secao.midias) {
        const midia = await MidiasAction.getMidia(item.chave)
        midiasGet.push({ src: midia, altText: item.legenda, caption: item.legenda, legenda: item.legenda, chave: item.chave, funcao: item.funcao })
      }
      let grouped = _.groupBy(midiasGet, function (item) {
        return item.funcao;
      });
      setItems(grouped)
    }
    if (secao.midias)
      getMidias()

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [secao]);

  return (
    <>
      <div className="wrapper">
        <SecaoPageHeader titulo={secao.titulo} />
        <div className="section">
          <Container>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <CarouselFull
                  lista={secao.midias && items.Superior ? items.Superior : []}
                />
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row>
              <Col md="12">
                <div style={{ textAlign: "justify", textJustify: "inter-word" }} className="ck-content" dangerouslySetInnerHTML={{ __html: secao.texto }}></div>
              </Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <CarouselFull
                  lista={secao.midias && items.Galeria ? items.Galeria : []}
                />
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <CarouselFull
                  lista={secao.midias && items.Inferior ? items.Inferior : []}
                />
                <div style={{ marginBottom: 50 }}></div>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default SecaoPage;
