/*eslint-disable*/
import React from "react";
import { api } from '../../store/action/settings'
// reactstrap components
import {
  Button, Container, Row, Col, UncontrolledTooltip,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Collapse
} from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

function DarkFooter() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const secoes = useSelector(store => store.secoes)
  const midiasgeral = useSelector(store => store.midiasgeral)


  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <Row className="text-center mt-5">
          <Col className="ml-auto mr-auto" md="8">
            <h2>Doutor Call Telemedicina</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn-round mr-1"
              color="info"
              role="button"
              tag={Link}
              to="/cadastro?type=medico"
              size="lg"
            >Sou um profissional da saúde, quero atender!</Button>
            <Button
              className="btn-round"
              color="primary"
              outline
              role="button"
              tag={Link}
              to="/cadastro?type=paciente"
              size="lg"
            >
              Sou um paciente, preciso de atendimento
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center sharing-area text-center">
          <Col className="text-center" lg="8" md="12">
            <h3>Queremos tornar as teleconsultas em saúde tão simples como um clique.</h3>
          </Col>
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn-neutral btn-icon btn-round"
              color="facebook"
              href="http://www.facebook.com/doutorcall"
              id="tooltip735272548"
              size="lg"
              target="_blank"
            >
              <i className="fab fa-facebook-square"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip735272548">
              Curta nossa página
            </UncontrolledTooltip>
            <Button
              className="btn-neutral btn-icon btn-round"
              color="instagram"
              href="https://instagram.com/doutorcall"
              id="tooltip735272549"
              size="lg"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip735272549">
              Siga o nosso perfil
            </UncontrolledTooltip>
            <Button
              className="btn-neutral btn-icon btn-round"
              color="linkedin"
              href="https://www.linkedin.com/company/65494370"
              id="tooltip735272547"
              size="lg"
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip735272547">
              Seja nosso parceiro
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center" style={{ marginBottom: 20, marginTop: 20 }}>
          <nav>
            <ul>
              {secoes.map((item, key) => {
                /*
                  0 - Cabeçalho
                  1 - Rodapé
                  2 - Capa
                */
                if (item.tipo === 1)
                  return <li key={key}>
                    <Link to={`/secao/${item.chave}`}>
                      {item.menu}
                    </Link>
                  </li>
              })}
            </ul>
          </nav>
        </Row>
        <Row className="justify-content-md-center text-center" style={{ marginBottom: 20, marginTop: 20 }}>
          <Col md="12" style={{ marginBottom: 10 }}>
            Aceitos os cartões de Crédito e Débito
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/mastercard.png")}
            ></img>
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/visa.png")}
            ></img>
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/american.png")}
            ></img>
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/hipercard.png")}
            ></img>
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/elo.png")}
            ></img>
          </Col>
          <Col xs="2" md="1" style={{ backgroundColor: "white", paddingTop: 1, paddingBottom: 1, }}>
            <img
              alt="..."
              src={require("assets/img/creditcards/dinners.png")}
            ></img>
          </Col>
          <Col xs={12}>
            <br />
            {midiasgeral.find(item => item.funcao === "Termos") ?
              <>
                Conheça os nossos {' '}
                <a rel="noopener noreferrer"
                  href={`${api}/open/midiageral/${midiasgeral.find(item => item.funcao === "Termos").chave}`}
                  target="_blank">
                  Termos de Uso{' '}
                </a>
              </>
              : null
            }
            {midiasgeral.find(item => item.funcao === "Politica") ?
              <>
                e {' '}
                <a rel="noopener noreferrer"
                  href={`${api}/open/midiageral/${midiasgeral.find(item => item.funcao === "Politica").chave}`}
                  target="_blank">
                  Política de Privacidade.{' '}
                </a>
              </>
              : null
            }
            <br />
          </Col>

        </Row>
        <nav>
          <ul>
            <li>
              SAC (54) 98168-2135
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Criado e mantido por Doutor Call Telemedicina.
        </div>
      </Container>
    </footer >
  );
}

export default DarkFooter;
