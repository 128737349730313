//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
//a importação abaixo tem por finalidade o controle de loading universal na aplicação
import * as loadingAction from './loading.actions';
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

export const GET_DOCUMENTS = 'GET_DOCUMENTS'
// Action Creators



export function getDocuments() {
    return (dispatch) => {
        dispatch(loadingAction.changeLoading())

        axios.get(`${api}/open/documents`)
            .then(async (response) => {
                const { data } = response.data
                await dispatch({ type: GET_DOCUMENTS, dados: data })
                await dispatch(loadingAction.changeLoading())
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
