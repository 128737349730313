import * as actions from '../action';
const initialState = false
function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case actions.CHANGE_LOADING:
            return !state
        default:
            return state
    }
} 

export default loadingReducer