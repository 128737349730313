//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

// Action Creators

const trataErro = (erro) => {
    if (erro.response)
        return erro.response.data.error
    else
        return "Problemas de conexão com o servidor!"
}

export function postUser(user) {
    return new Promise((resolve, reject) => {
        axios.post(`${api}/auth/signup`, user)
            .then(response => {
                const { data } = response.data
                if (data) {
                    resolve()
                }
            })
            .catch((erro) => {
                reject(trataErro(erro))
            });

    })
}

export function sendInteresse(data) {
    return new Promise((resolve, reject) => {
        axios.post(`${api}/open/contatosrecebidos`, data)
            .then(response => {
                const { data } = response.data
                if (data) {
                    resolve()
                }
            })
            .catch((erro) => {
                reject(trataErro(erro))
            });
    })

}

export function resetPassword(email) {
    return new Promise((resolve, reject) => {
        axios.get(`${api}/auth/resetpassword/${email}`)
            .then(response => {
                const { status } = response.data
                if (status) {
                    resolve()
                }
            })
            .catch((erro) => {
                reject(trataErro(erro))
            });
    })
}

export function envioemailativacao(email) {
    return new Promise((resolve, reject) => {
        axios.get(`${api}/auth/envioemailativacao/${email}`)
            .then(response => {
                const { status } = response.data
                if (status) {
                    resolve()
                }
            })
            .catch((erro) => {
                reject(trataErro(erro))
            });
    })
}


export function validateUser(chave, validate) {
    return new Promise((resolve, reject) => {
        let url = ""
        let msg = ""
        if (validate) {
            url = `${api}/auth/validate/${chave}`
            msg = "E-mail validado com sucesso."
        }
        else {
            url = `${api}/auth/unvalidate/${chave}`
            msg = "E-mail desativado com sucesso."
        }

        axios.get(url)
            .then(response => {
                const { data } = response.data
                if (data) {
                    resolve({ message: msg })
                }
            })
            .catch((erro) => {
                if (erro)
                    reject(trataErro(erro))
            });

    })

}