import * as actions from '../action';
const initialState = []

function postsSearchReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_POSTSSEARCH:
            return action.dados
        default:
            return state
    }
}
export default postsSearchReducer