import React, { useLayoutEffect, useEffect, useState } from "react";
import {
  Button,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/core";
import ModalMessage from "../../components/Elements/ModalMessage"
import * as PostSearchAction from "../../store/action/postssearch.actions"
import moment from 'moment'
import { Link } from "react-router-dom";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


function PostsPage() {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 10,
    }),
  }

  const postssearch = useSelector(store => store.postssearch)
  const [mensagem] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [limit] = useState([
    {
      label: "10 Registros",
      value: 10
    },
    {
      label: "15 Registros",
      value: 15
    },
    {
      label: "20 Registros",
      value: 20
    },
    {
      label: "50 Registros",
      value: 50
    },
    {
      label: "100 Registros",
      value: 100
    }
  ])
  const [limitValue, setLimitValue] = useState(limit[0])
  const [search, setsearch] = useState("")

  const sendSearch = () => {
    setLoaging(true)
    Promise.all([
      dispatch(PostSearchAction.getPostsSearch(limitValue.value, search, false))
    ]).then(() => setLoaging(false))
  }
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoaging] = useState(false);
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    setLoaging(true)
    Promise.all([
      dispatch(PostSearchAction.getPostsSearch(10, '', false))
    ]).then(() => setLoaging(false))
  }, [dispatch])

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [postssearch]);



  return (
    <>
      <ModalMessage
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mensagem={mensagem}
      />
      <div className="wrapper">
        <LandingPageHeader titulo="" />
        <div style={{ padding: 10, marginRight: 15 }}>
          <Row style={{ justifyContent: "center" }}>
            <Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Select
                noOptionsMessage={() => "Não foi Encontrado"}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: 'gray',
                  },
                })}
                placeholder="Número de Registros"
                name="colors"
                options={limit}
                value={limitValue}
                onChange={(e) => setLimitValue(e)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Col>
            <Col sm="3" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Input
                style={{ minHeight: "38px", borderRadius: 5, width: "95%" }}
                name="search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                placeholder={"Pesquisa..."}
                type="text"
              ></Input>
            </Col>
            <Col sm="1" xs="12" style={{ paddingRight: 0, marginBottom: 5 }}>
              <Button onClick={sendSearch} style={{ margin: 0, width: "100%" }} id="buttonSearch">
                {width < 575 ? " Pesquisar " : ""}
                <i className="now-ui-icons ui-1_zoom-bold"></i>
              </Button>
              <UncontrolledTooltip target="#buttonSearch">
                Pesquisar
            </UncontrolledTooltip>

            </Col>
          </Row>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Fique por dentro da área da saúde!</h2>
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={20}
              color={'#007bff'}
              loading={loading}
            />

            <p className="description">Abaixo os últimos posts com contéudos relacionados a área da saúde.</p>
            <Row>
              {postssearch.map((item, key) =>
                <Col className="text-center ml-auto mr-auto" lg="10" md="10" key={key}>
                  <div style={{ paddingLeft: 10, paddingRight: 10, minWidth: 150, textAlign: "center", justifyContent: "center", height: 20, float: "right", backgroundColor: 'rgb(132, 76, 156)', }}>
                    <span
                      style={{ fontSize: 10, color: "white" }}
                    >
                      {item.categoria} - {moment(item.data, 'YYYY-MM-DDThh:mm:ss a').format("DD/MM/YYYY hh:mm")}
                    </span>
                  </div>
                  <div className="blockquote blockquote-info">
                    <Button
                      className="btn-icon btn-round"
                      style={{ float: "left", backgroundColor: 'rgb(132, 76, 156)' }}
                      tag={Link}
                      to={`/post/${item.chave}`}
                    >
                      <i className="now-ui-icons gestures_tap-01"></i>
                    </Button>
                    <h3>
                      <Link to={`/post/${item.chave}`} style={{ color: 'rgb(132, 76, 156)' }}>
                        {item.titulo}
                      </Link>
                    </h3>
                    <p className="text-muted" style={{ fontSize: 10 }}>
                      {item.subtitulo}
                    </p>


                  </div>

                </Col>
              )}
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default PostsPage;
