import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Container
} from "reactstrap";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/core";
import * as userAction from '../../store/action/user.actions'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


function ValidatePage(props) {
  const [msg, setMsg] = useState(null);
  const [loading, setLoaging] = useState(false);

  useLayoutEffect(() => {
    let chave = props.match.params.chave
    let validate = props.validate
    setLoaging(true)
    setTimeout(() => {
      userAction.validateUser(chave, validate)
        .then((res) => {
          setLoaging(false)
          setMsg(res.message)
        })
        .catch((error) => {
          setLoaging(false)
          setMsg(error)
        })
    }, 1000);

  }, [props.validate, props.match.params.chave]);

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);



  return (
    <>
      <div className="wrapper">
        <LandingPageHeader titulo={msg} />
        <div className="section section-contact-us text-center">
          <Container>
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={20}
              color={'#007bff'}
              loading={loading}
            />
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default ValidatePage;
