import React, { useLayoutEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import * as qs from 'query-string';
import { api } from '../../store/action/settings'

import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  UncontrolledTooltip,
  FormGroup,
  Label
} from "reactstrap";
import { useSelector } from 'react-redux'
import Select from 'react-select'
import ReCAPTCHA from "react-google-recaptcha";
// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { BeatLoader } from 'react-spinners';
import { css } from "@emotion/core";
import * as userAction from '../../store/action/user.actions'
import InputMask from "react-input-mask";
import ModalMessage from "../../components/Elements/ModalMessage"
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


function CreateUserPage(props) {

  const customStyles = {
    valueContainer: styles => ({
      ...styles,
      justifyContent: 'center',
    }),
    control: styles => ({
      ...styles,
      borderRadius: '50px!important',
      padding: 4,
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 10,
    }),
  }

  const [focus, setFocus] = useState({});
  const { register, handleSubmit, watch, errors, reset } = useForm()
  const [mensagem, setMensagem] = useState(null);
  const [loading, setLoaging] = useState(false);
  const [pills, setPills] = useState(1);
  const documents = useSelector(store => store.documents)
  const typesDoutorStore = useSelector(store => store.typesDoutor)
  const typesPacienteStore = useSelector(store => store.typesPaciente)
  const midiasgeral = useSelector(store => store.midiasgeral)

  //const [documentsData, setDocumentsData] = useState({})

  const [types, setTypes] = useState([])
  const [typeSelected, setTypeSelected] = useState(null)
  const [modalOpen, setModalOpen] = React.useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [checked, setChecked] = useState(false);


  const onSubmit = data => {
    setLoaging(true)
    delete data.repassword;
    data.idtype = typeSelected.value

    console.log(data)
    userAction.postUser(data)
      .then(() => {
        setLoaging(false)
        setMensagem({
          titulo: 'Cadastro realizado com sucesso',
          msg: "Parabéns, seu registro foi efetuado em nosso sistema. Enviaremos um e-mail para confirmar o usuário.",
          success: true
        })
        setModalOpen(true)
        //setDocumentsData({})
        reset()
      })
      .catch((erro) => {
        setMensagem({ titulo: "Verifique", msg: erro, success: false })
        setModalOpen(true)
        setLoaging(false)
      })
  }

  useLayoutEffect(() => {
    if (typesDoutorStore && typesDoutorStore.length > 0) {
      let dados = typesDoutorStore.map((item, key) => {
        return {
          value: item.idtype,
          label: item.descricao
        }
      })
      Promise.all(dados).then(res => {
        setTypeSelected(res.find(item => item.label === "Médico"))
        setTypes(res)
      })
    }

  }, [typesDoutorStore])

  React.useEffect(() => {
    const parsed = qs.parse(props.location.search);
    if (parsed.type === "medico") {
      setPills(2)
      setTypeSelected(types.find(item => item.label === "Médico"))
      //só para dar tempo de atualizar
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    } else if (parsed.type === "paciente") {
      setPills(1)
      //só para dar tempo de atualizar
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
    if (pills === 1) {
      if (typesPacienteStore.length > 0)
        setTypeSelected({
          label: typesPacienteStore[0].descricao,
          value: typesPacienteStore[0].idtype
        })
    }
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [props.location.search, types, typesPacienteStore, pills]);



  return (
    <>
      <ModalMessage
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mensagem={mensagem}

      />
      <div className="wrapper">
        <LandingPageHeader titulo="" />
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Crie sua conta. É gratuito!</h2>
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={20}
              color={'#007bff'}
              loading={loading}
            />

            <p className="description">Escolha o seu perfil:</p>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center" style={{ marginBottom: 15 }}>
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === 1 ? "active" : ""}
                        href="#"
                        id="tutor"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills(1);
                          props.location.search = ''
                          setTypeSelected({
                            label: typesPacienteStore[0].descricao,
                            value: typesPacienteStore[0].idtype
                          })
                        }}
                      >
                        <i className="now-ui-icons users_single-02"></i>
                      </NavLink>
                      <UncontrolledTooltip target="#tutor">
                        Paciente
                      </UncontrolledTooltip>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === 2 ? "active" : ""}
                        href="#"
                        id="medico"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills(2);
                          props.location.search = ''
                          setTypeSelected({
                            label: typesDoutorStore[0].descricao,
                            value: typesDoutorStore[0].idtype
                          })

                        }}
                      >
                        <i className="fa fa-user-md lg"></i>
                      </NavLink>
                      <UncontrolledTooltip target="#medico">
                        Médico
                      </UncontrolledTooltip>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {pills === 2 &&
                    <div style={{ paddingLeft: "40px", paddingRight: "40px", marginBottom: "10px" }}>
                      <Select
                        styles={customStyles}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: 'gray',
                          },
                        })}
                        value={typeSelected}
                        placeholder="Selecione abaixo:"
                        options={types}
                        onChange={(object) => setTypeSelected(object)}
                        className="input-lg"
                        classNamePrefix="select"
                      />
                    </div>
                  }

                  <InputGroup
                    className={
                      "input-lg" + (focus.nome ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_single-02"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="nome"
                      placeholder={pills === 1 ? "Nome Completo do Paciente" : "Nome Completo do Médico"}
                      innerRef={register({ required: true })}
                      type="text"
                      onFocus={() => setFocus({ nome: true })}
                      onBlur={() => setFocus({ nome: false })}
                    ></Input>
                  </InputGroup>
                  {errors.nome && <span>Este campo é obrigatório.</span>}
                  <InputGroup
                    className={
                      "input-lg" + (focus.email ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      placeholder="E-mail"
                      innerRef={register({ required: true })}
                      type="email"
                      onFocus={() => setFocus({ email: true })}
                      onBlur={() => setFocus({ email: false })}
                    ></Input>
                  </InputGroup>
                  {errors.email && <span>Este campo é obrigatório.</span>}
                  <InputGroup
                    className={
                      "input-lg" + (focus.datanascimento ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_calendar-60"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      //value={documentsData[item.descricao] ? documentsData[item.descricao] : ''}
                      //onChange={(e) => setDocumentsData(Object.assign({}, documentsData, { [item.descricao]: e.target.value }))}
                      name="datanascimento"
                      inputRef={register({ required: true })}
                      placeholder="Data Nascimento"
                      type="text"
                      mask={"99/99/9999"}
                      maskChar=" "
                      tag={InputMask}
                      onFocus={() => setFocus({ datanascimento: true })}
                      onBlur={() => setFocus({ datanascimento: false })}
                    ></Input>
                  </InputGroup>
                  {errors.datanascimento && <span>Este campo é obrigatório.</span>}
                  <InputGroup
                    className={
                      "input-lg" + (focus.telefone ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons tech_mobile"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      //value={documentsData[item.descricao] ? documentsData[item.descricao] : ''}
                      //onChange={(e) => setDocumentsData(Object.assign({}, documentsData, { [item.descricao]: e.target.value }))}
                      name="fone"
                      inputRef={register({ required: true })}
                      placeholder="Telefone"
                      type="text"
                      mask={"(99) 99999 9999"}
                      maskChar=" "
                      tag={InputMask}
                      onFocus={() => setFocus({ telefone: true })}
                      onBlur={() => setFocus({ telefone: false })}
                    ></Input>
                  </InputGroup>
                  {errors.telefone && <span>Este campo és obrigatório.</span>}
                  <InputGroup
                    className={
                      "input-lg" + (focus.senha ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons objects_key-25"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="password"
                      placeholder="Senha"
                      type="password"
                      minLength="6"
                      innerRef={register({ required: true })}
                      onFocus={() => setFocus({ senha: true })}
                      onBlur={() => setFocus({ senha: false })}
                    ></Input>
                  </InputGroup>
                  {errors.password && <span>Este campo é obrigatório.</span>}
                  <InputGroup
                    className={
                      "input-lg" + (focus.senharetry ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons objects_key-25"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="repassword"
                      placeholder="Repetir a Senha"
                      innerRef={register({ required: true, validate: (value) => value === watch('password') })}
                      minLength="6"
                      type="password"
                      onFocus={() => setFocus({ senharetry: true })}
                      onBlur={() => setFocus({ senharetry: false })}
                    ></Input>
                  </InputGroup>
                  {errors.repassword && <span>Este campo é obrigatório e deve possuir o mesmo valor de senha.</span>}
                  {documents.length > 0 ?
                    documents.map((item, key) => {
                      if (typeSelected && item.idtype === typeSelected.value && item.mandatory) {
                        return (
                          <InputGroup key={key}
                            className={
                              "input-lg" + (focus[item.descricao] ? " input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons business_badge"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              //value={documentsData[item.descricao] ? documentsData[item.descricao] : ''}
                              //onChange={(e) => setDocumentsData(Object.assign({}, documentsData, { [item.descricao]: e.target.value }))}
                              name={item.descricao}
                              inputRef={register({ required: true })}
                              placeholder={item.descricao === "CPF" || item.descricao === "RG" ? item.descricao : `Registro Profissional - ${item.descricao}`}
                              type="text"
                              mask={item.mascara}
                              maskChar=" "
                              tag={InputMask}
                              onFocus={() => setFocus({ [item.descricao]: true })}
                              onBlur={() => setFocus({ [item.descricao]: false })}
                            ></Input>
                          </InputGroup>
                        )

                      } else return null
                    }) : null}
                  <div style={{ fontSize: 12, alignItems: "center", marginBottom: 10 }}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" value={checked} onChange={() => setChecked(!checked)}></Input>
                        <span className="form-check-sign"></span>
                        {midiasgeral.find(item => item.funcao === "Termos") ?
                          <>
                            Li e concordo com os {' '}
                            <a rel="noopener noreferrer"
                              href={`${api}/open/midiageral/${midiasgeral.find(item => item.funcao === "Termos").chave}`}
                              target="_blank">
                              Termos de Uso{' '}
                            </a>
                          </>
                          : null
                        }
                        {midiasgeral.find(item => item.funcao === "Politica") ?
                          <>
                            e {' '}
                            <a rel="noopener noreferrer"
                              href={`${api}/open/midiageral/${midiasgeral.find(item => item.funcao === "Politica").chave}`}
                              target="_blank">
                              Política de Privacidade.{' '}
                            </a>
                          </>
                          : null
                        }
                        <br />
                        <div style={{ textAlign: "justify" }}>
                          Estou ciente e concordo que meus dados pessoais poderão ser utilizados
                          e compartilhados com outras empresas afiliadas ou
                          coligadas da plataforma Doutor Call,
                          como agentes, contratados ou parceiros comerciais,
                          para o propósito exclusivo da realização dos serviços oferecidos pela plataforma.
                        </div>
                      </Label>
                    </FormGroup>

                  </div>
                  <div className="send-button">
                    <ReCAPTCHA
                      style={{ display: 'inline-block' }}
                      size="normal"
                      sitekey="6LfUB8AZAAAAAD0MKjbnWYQYHJEAOAW4d6m4ZWoC"
                      onChange={(e) => {
                        if (e) setRecaptcha(true)
                        else setRecaptcha(false)
                      }}
                    />

                    {errors.check && <span>Este campo é obrigatório.</span>}
                    <Button
                      block
                      type="submit"
                      className="btn-round"
                      disabled={!recaptcha || !checked || loading}
                      color="info"
                      size="lg"
                    >
                      Enviar Cadastro
                    </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default CreateUserPage;
