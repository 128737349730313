import * as actions from '../action';
const initialState = []

function secoesReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_SECOES:
            return action.dados
        default:
            return state
    }
}
export default secoesReducer