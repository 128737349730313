import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Images from "./index-sections/Images.js";

import PostsCapa from "components/Elements/PostsCapa";
//import BasicElements from "./index-sections/BasicElements.js";
/*

import Navbars from "./index-sections/Navbars.js";
import Tabs from "./index-sections/Tabs.js";
import Pagination from "./index-sections/Pagination.js";
import Notifications from "./index-sections/Notifications.js";
import Typography from "./index-sections/Typography.js";
import Javascript from "./index-sections/Javascript.js";
import SecaoCapa from "components/Elements/SecaoCapa.js";

*/





function Index() {


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Images />
          {
            
            /*
            <SecaoCapa />
            <BasicElements />
            <Navbars />
            <Tabs />
            <Pagination />
            <Notifications />
            <Typography />
            <Javascript />
            */
          }
          <PostsCapa />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
