import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

function PostsCapa() {
    const [width, setWidth] = useState(window.innerWidth);
    const posts = useSelector(store => store.posts)

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));

    }, [])

    return (
        <>
            <div className="section section-about-us">
                <Container>
                    {
                        /*
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title" style={{ paddingTop: 0, marginBottom: 10 }}>SAIBA MAIS...</h2>
                        </Col>
                    </Row>

                        */
                    }
                    <div className="section-story-overview" style={{ padding: 0 }}>
                        {
                            posts.map((item, key) => {
                                if (item.midiaSuperior) {
                                    return <Row key={key} style={
                                        width > 991 ?
                                            { marginBottom: 100, marginTop: -100 }
                                            :
                                            width > 768 ?
                                                { marginBottom: 100, marginTop: -180 }
                                                :
                                                width < 370 ?
                                                    { marginBottom: 100, marginTop: -130 }
                                                    : null
                                    }
                                    >
                                        <Col sm="3" xs="12"></Col>
                                        <Col sm="2" xs="12" hidden={width < 768}>
                                            <p className="container blockquote blockquote-info">
                                                "{item.titulo}" - {item.categoria}
                                                <Button
                                                    className="btn-icon btn-round"
                                                    style={{ float: "left", backgroundColor: 'rgb(132, 76, 156)' }}
                                                    tag={Link}
                                                    to={`/post/${item.chave}`}
                                                >
                                                    <i className="now-ui-icons gestures_tap-01"></i>
                                                </Button>
                                            </p>
                                        </Col>
                                        <Col xs="12" hidden={width > 768}>
                                            <p className="blockquote blockquote-info" style={{ width: "100%", minHeight: 0 }}>
                                                "{item.titulo}" - {item.categoria}
                                                <Button
                                                    className="btn-icon btn-round"
                                                    style={{ float: "rigth", backgroundColor: 'rgb(132, 76, 156)' }}
                                                    tag={Link}
                                                    to={`/post/${item.chave}`}
                                                >
                                                    <i className="now-ui-icons gestures_tap-01"></i>
                                                </Button>
                                            </p>
                                        </Col>
                                        <Col md="5">
                                            <div
                                                className="image-container image-right"
                                                style={{
                                                    backgroundImage:
                                                        "url(" + item.midiaSuperior + ")",
                                                }}
                                            ></div>
                                            <p style={{ marginTop: 20, marginBottom: 30, textAlign: "justify", textJustify: "inter-word" }}>
                                                {item.subtitulo}
                                                <Link to={`/post/${item.chave}`}>Saiba mais...</Link>
                                            </p>
                                        </Col>
                                    </Row>
                                } else {
                                    return <Row key={key}
                                        style={
                                            width > 991 ?
                                                { marginBottom: 100, marginTop: -50 }
                                                :
                                                width > 768 ?
                                                    { marginBottom: 100, marginTop: -30 }
                                                    :
                                                    width < 370 ?
                                                        { marginBottom: 100, marginTop: -80 }
                                                        : null
                                        }
                                    >
                                        <Col sm="1" xs="12"></Col>
                                        <Col xs="6" hidden={width < 990}>
                                            <p style={{ marginTop: 20,textAlign: "justify", textJustify: "inter-word" }}>
                                                {item.subtitulo}
                                                <Link to={`/post/${item.chave}`}>Saiba mais...</Link>
                                            </p>
                                        </Col>

                                        <Col xs="12" hidden={width > 990}>
                                            <p className="blockquote blockquote-info" style={{ width: "100%", minHeight: 0 }}>
                                                "{item.titulo}" - {item.categoria}
                                                <Button
                                                    className="btn-icon btn-round"
                                                    style={{ float: "left", backgroundColor: 'rgb(132, 76, 156)' }}
                                                    tag={Link}
                                                    to={`/post/${item.chave}`}
                                                >
                                                    <i className="now-ui-icons gestures_tap-01"></i>
                                                </Button>
                                            </p>
                                        </Col>
                                        <Col xs="12" hidden={width > 990}>
                                            <p style={{marginBottom:50,textAlign: "justify", textJustify: "inter-word"}}>
                                                {item.subtitulo}
                                                <Link to={`/post/${item.chave}`}>Saiba mais...</Link>
                                            </p>
                                        </Col>

                                        <Col sm="2" hidden={width < 990}>
                                            <p className="blockquote blockquote-info">
                                                "{item.titulo}" - {item.categoria}
                                                <Button
                                                    className="btn-icon btn-round"
                                                    style={{ float: "left", backgroundColor: 'rgb(132, 76, 156)' }}
                                                    tag={Link}
                                                    to={`/post/${item.chave}`}
                                                >
                                                    <i className="now-ui-icons gestures_tap-01"></i>
                                                </Button>
                                            </p>
                                        </Col>

                                    </Row>
                                }


                            }
                            )}



                        <Row style={{ marginLeft: 80, marginRight: 80 }} hidden={width < 990}>
                            <p className="blockquote blockquote-info" style={{ width: "100%", minHeight: 10, padding: 5, textAlign: "center" }}>
                                <Button
                                    className="btn-icon btn-round"
                                    style={{ backgroundColor: 'rgb(132, 76, 156)' }}
                                    tag={Link}
                                    to={`/posts`}
                                >
                                    <i className="now-ui-icons gestures_tap-01"></i>
                                </Button>
                                <span style={{ marginLeft: 10, marginTop: 15 }}>
                                    <Link to={`/posts`} style={{ color: 'rgb(132, 76, 156)' }}>
                                        Visualize nossos conteúdos e fique por dentro da área da saúde!
                                    </Link>
                                </span>
                            </p>
                        </Row>
                        <Row style={{ marginLeft: 10, marginRight: 10 }} hidden={width > 990}>
                            <p className="blockquote blockquote-info" style={{ width: "100%", minHeight: 10, padding: 5, textAlign: "center" }}>
                                <Button
                                    className="btn-icon btn-round"
                                    style={{ backgroundColor: 'rgb(132, 76, 156)' }}
                                    tag={Link}
                                    to={`/posts`}
                                >
                                    <i className="now-ui-icons gestures_tap-01"></i>
                                </Button>
                                <span style={{ marginLeft: 10, marginTop: 15 }}>
                                    <Link to={`/posts`} style={{ color: 'rgb(132, 76, 156)' }}>
                                        Visualize nossos conteúdos e fique por dentro da área da saúde!
                                    </Link>
                                </span>
                            </p>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PostsCapa;