import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  NavLink,
  Button,
  Nav,
  Container,
  UncontrolledTooltip,
  Row, Col
} from "reactstrap";
import { app } from '../../store/action/settings'
import { useSelector, useDispatch } from 'react-redux'
import * as documentsAction from '../../store/action/documents.actions'
import * as especialidadesAction from '../../store/action/especialidades.actions'
import * as areasAtuacaoAction from '../../store/action/areasatuacao.actions'

import * as secoesAction from '../../store/action/secoes.actions'
import * as typesAction from '../../store/action/types.actions'
import * as postsAction from '../../store/action/posts.actions'
import * as conveniosAction from '../../store/action/convenios.actions'
import * as MidiasGeralAction from '../../store/action/midiasgeral.actions'
import useGaTracker from '../../useGaTracker'


function IndexNavbar() {
  const dispatch = useDispatch()
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const secoes = useSelector(store => store.secoes)
  const [logo, setLogo] = useState(require("assets/img/doutorcallfull.png"))
  const [disclaimer, setDisclaimer] = useState(false)

  useGaTracker();

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("");
        setLogo(require("assets/img/doutorcallfull.png"))
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        setLogo(require("assets/img/doutorcallfull.png"))
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  useLayoutEffect(() => {

    if (localStorage.getItem("disclaimer"))
      setDisclaimer(false)
    else
      setDisclaimer(true)

    dispatch(documentsAction.getDocuments())
    dispatch(especialidadesAction.getEspecialidades())
    dispatch(areasAtuacaoAction.getAreasAtuacao())
    dispatch(conveniosAction.getConvenios())

    dispatch(secoesAction.getSecoes())
    dispatch(postsAction.getPosts(2, '', true))

    dispatch(MidiasGeralAction.getMidiasgGeral())



    dispatch(typesAction.getTypesDoutor())
    dispatch(typesAction.getTypesPaciente())

  }, [dispatch])

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      {disclaimer &&
        <div style={{
          backgroundColor: "white",
          display: "block",
          visibility: "visible",
          padding: "10px 40px 10px 10px",
          bottom: 0,
          position: "fixed",
          right: 0,
          left: 0,
          zIndex: 900,
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 3px, rgba(0, 0, 0, 0.24) 0px 0px 2px"
        }}>
          <Row>
            <Col sm="10" xs="8" style={{ color: "black", display: "flex", fontSize: 12, alignItems: "center", justifyContent: "center" }} >
              <span >
                Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação.
                Ao utilizar nossos serviços você concorda com tal monitoramento.
              </span>
            </Col>
            <Col sm="2" xs="4">
              <Button
                type="button"
                style={{
                  marginLeft: 10, backgroundColor: "white", color: "black",
                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 3px, rgba(0, 0, 0, 0.24) 0px 0px 2px"
                }}
                onClick={() => {
                  localStorage.setItem("disclaimer", true)
                  setDisclaimer(false)
                }}
              >
                Aceitar
              </Button>
            </Col>
          </Row>
        </div>
      }

      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="white" style={{ zIndex: 1000 }}>
        <Container>
          <div className="navbar-translate">
            <NavLink
              tag={Link}
              to={`/`}
              id="home"
            >
              <img
                alt="..."
                className="invision-logo"
                src={logo}
              ></img>
            </NavLink>
            <UncontrolledTooltip target="#home">
              Capa
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              {secoes.map((item, key) => {
                /*
                  0 - Cabeçalho
                  1 - Rodapé
                  2 - Capa
                */
                if (item.tipo === 0)
                  return <NavItem key={key}>
                    <NavLink
                      tag={Link}
                      to={`/secao/${item.chave}`}
                    >
                      {item.menu}
                    </NavLink>
                  </NavItem>
                return null
              })}

              <NavItem>
                <NavLink
                  tag={Link}
                  to={`/cadastro`}
                >
                  <i className="fa fa-address-card" style={{ marginRight: 5 }}></i>
                  Cadastre-se
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={`/parceiro`}
                >
                  <i className="fa fa-handshake" style={{ marginRight: 5 }}></i>
                  Seja Parceiro
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons design_app mr-1"></i>
                  <p>Opções</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    to="/esqueceu" tag={Link}>
                    <i className="fa fa-key" style={{ marginRight: 5 }}></i>
                    Esqueçeu a senha
                  </DropdownItem>
                  <DropdownItem
                    to="/mail-validate" tag={Link}>
                    <i className="fa fa-envelope" style={{ marginRight: 5 }}></i>
                    E-mail de validação
                  </DropdownItem>
                  <DropdownItem
                    to="/login" tag={Link}>
                    <i className="fa fa-map-marker" style={{ marginRight: 5 }}></i>
                    Local de Atendimento
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href={`${app}/login`}
                >
                  <i className="fa fa-key" style={{ marginRight: 5 }}></i>
                  ENTRAR
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="http://www.facebook.com/doutorcall"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Curta nossa página no Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://instagram.com/doutorcall"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Siga-nos no Instagram
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/company/65494370"
                  target="_blank"
                  id="linkedin-tooltip"
                >
                  <i className="fab fa-linkedin"></i>
                  <p className="d-lg-none d-xl-none">Linkedin</p>
                </NavLink>
                <UncontrolledTooltip target="#linkedin-tooltip">
                  Seja nosso parceiro no Linkedin
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
