import * as actions from '../action';
const initialState = []
function typesDoutorReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_TYPESDOUTOR:
            return action.dados
        default:
            return state
    }
}
export default typesDoutorReducer