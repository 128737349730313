//neste exemplo de código estamos usando como back-end o Firebase
import { api } from './settings'
// Action Types (variáveis abaixo) são a ponte entre as Actions e Reducers.
import axios from 'axios';

// Action Creators

const trataErro = (erro) => {
    if (erro.response)
        return erro.response.data.error
    else
        return "Problemas de conexão com o servidor!"
}

export function signin(param) {
    return new Promise((resolve, reject) => {
        axios.post(`${api}/open/localatendimento/signin`, param)
            .then(response => {
                const { token } = response.data
                localStorage.setItem("local", token)
                if (token) {
                    resolve()
                }
            })
            .catch((erro) => {
                reject(trataErro(erro))
            });

    })
}


export function getLocal() {
    axios.defaults.headers.common['local'] = getToken();

    return new Promise((resolve, reject) => {
        axios.get(`${api}/open/localatendimento/get`)
            .then(response => {
                resolve(response.data.data)
            })
            .catch((erro) => {
                delToken()
                reject(trataErro(erro))
            });

    })
}

export function getToken() {
    let token = sessionStorage.getItem("local")
    if (!token) token = localStorage.getItem("local")
    return token
}


export function delToken() {
    localStorage.removeItem("local")
    sessionStorage.removeItem("local")
}



